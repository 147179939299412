#header {
  background: $light-color;
  .header-transparent &:not(.scrolled) { 
    background: rgba(#fff, 0.5);
  }
}
.modular-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
  .columns.left {
    flex-direction: row-reverse;
  }
  .table { 
    border-collapse: collapse;
    width: 100%;
  }
  tr,
  td,
  th {
    border: 0;
    padding: 0 $unit-3;
    background-color: $bg-color;
    color: black;
  }
}

.section.section { 
  padding-left: 0; 
  padding-right: 0; 
  position: relative; 
}


// Overlay
.image-overlay {
  position: absolute;
  top: 0; 
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  .overlay-light & {
    background: rgba(#fff, 0.2);
  }

  .overlay-light-gradient & {
    background: linear-gradient(to bottom, rgba(#fff,0.3), rgba(#fff,0));
  }

  .overlay-dark & {
    background: rgba(#000, 0.4);
  }

  .overlay-dark-gradient & {
    background: linear-gradient(to bottom, rgba(#000,0.5), rgba(#000,0.2));
  }
}

.table { 
    border-collapse: collapse;
    width: 100%;
  }
tr {
  border: 1px solid black; 
  background-color: lightblue;
  color: black;
}

th {
  border: 1px solid black; 
  background-color: navy;
  color: ivory;
}
